import { Dispatch } from 'redux';
import { createSlice, createSelector } from 'redux-starter-kit';
import { format } from 'date-fns';
import {
    UserType,
    TransactionType,
    ItemType,
    TransactionStatus,
    DeliveryOptionTypes,
    DeliveryOption,
} from 'types';
import request from 'utils/request';

import { getUserTotals, selectUser } from 'services/authentication';
import { isNewFlow } from 'utils/constants';

import itemsSlice from 'services/items';

export type TransactionsSliceType = {
    transactions: TransactionType[];
    error: string;
    currentTransaction: null | TransactionType;
};

export type UpdateTransactionValuesType = {
    destinationId?: number;
    buyerId?: number;
    sellerId?: number;
    status?: TransactionStatus;
    deliveryOption?: DeliveryOption;
    volumeDiscount?: number | null;
};

const initialState: TransactionsSliceType = {
    transactions: [],
    error: '',
    currentTransaction: null,
};

const injectItemIntoTransactionState = (transaction: TransactionType, item: ItemType) => {
    const items = transaction.items;
    const itemIdx = items.findIndex((transactionItem: ItemType) => transactionItem.id === item.id);
    if (itemIdx === -1) {
        items.push(item);
    } else {
        items[itemIdx] = item;
    }
    transaction.items = items;
    return transaction;
};

const removeItemFromTransactionState = (transaction: TransactionType, item: ItemType) => {
    const items = transaction.items;
    const itemIdx = items.findIndex((transactionItem: ItemType) => transactionItem.id === item.id);
    if (itemIdx !== -1) {
        items.splice(itemIdx, 1);
    }
    transaction.items = items;
    return transaction;
};

// exports: actions, reducer, selectors, slice
const transactions = createSlice({
    slice: 'transactions',
    initialState,
    reducers: {
        transactionsSuccess(state, action) {
            state.transactions = action.payload;
            state.error = '';
        },
        transactionsFail(state, action) {
            state.transactions = [];
            state.error = 'Error';
        },
        setCurrentTransaction(state, action) {
            state.currentTransaction = action.payload;
            state.error = '';
        },
        getTransactionError(state, action) {
            state.currentTransaction = null;
            state.error = 'Error';
        },
    },
    extraReducers: {
        [itemsSlice.actions.itemsSuccess]: (state, action) => {
            const transactionIdx = state.transactions.findIndex(
                (transaction: TransactionType) => transaction.id === action.payload.transactionId,
            );
            if (transactionIdx !== -1) {
                state.transactions[transactionIdx] = injectItemIntoTransactionState(
                    state.transactions[transactionIdx],
                    action.payload,
                );
            }
            if (
                state.currentTransaction &&
                state.currentTransaction.id === action.payload.transactionId
            ) {
                state.currentTransaction = injectItemIntoTransactionState(
                    state.currentTransaction,
                    action.payload,
                );
            }
        },
        [itemsSlice.actions.itemsDeleteSuccess]: (state, action) => {
            const transactionIdx = state.transactions.findIndex(
                (transaction: TransactionType) => transaction.id === action.payload.transactionId,
            );
            if (transactionIdx !== -1) {
                state.transactions[transactionIdx] = removeItemFromTransactionState(
                    state.transactions[transactionIdx],
                    action.payload,
                );
            }
            if (
                state.currentTransaction &&
                state.currentTransaction.id === action.payload.transactionId
            ) {
                state.currentTransaction = removeItemFromTransactionState(
                    state.currentTransaction,
                    action.payload,
                );
            }
        },
    },
});

export default transactions;

const { actions, selectors } = transactions;

// Additional selectors
export const selectTransactions = () =>
    createSelector(
        [selectors.getTransactions, selectUser()],
        (subState, user) =>
            subState.transactions.map((transaction: TransactionType) => ({
                ...transaction,
                points: getPoints(user, transaction, transaction.points),
            })),
    );

export const selectError = () =>
    createSelector(
        [selectors.getTransactions],
        (subState) => subState.error,
    );

export const selectCurrentTransaction = () =>
    createSelector(
        [selectors.getTransactions],
        (subState) => subState.currentTransaction,
    );

// Requests
const getAllTransactionsRequest = () =>
    request<{ data: TransactionType[] }>(`/api/v1/transactions`, {
        method: 'GET',
    });

const getTransactionsRequest = () =>
    request<{ data: TransactionType[] }>(`/api/v1/transactions/my-transactions`, {
        method: 'GET',
    });

const createTransactionRequest = () =>
    request<{ data: TransactionType }>(`/api/v1/transactions`, {
        method: 'POST',
    });

const createTransactionFromBuyerRequest = (supplierId: number) =>
    request<{ data: TransactionType }>(`/api/v1/transactions`, {
        method: 'PUT',
        data: { supplierId },
    });

const updateTransactionRequest = (transactionId: number, values: UpdateTransactionValuesType) =>
    request<{ transaction: TransactionType }>(`/api/v1/transactions/${transactionId}`, {
        method: 'PUT',
        data: values,
    });

const updateTransactionsVolumeDiscountRequest = (userId: number) => request(`/api/v1/transactions/${userId}/update-volume-discount`, {
    method: 'POST',
});

const getTransactionRequest = (transactionId: number) =>
    request<{ data: TransactionType }>(`/api/v1/transactions/${transactionId}`, {
        method: 'GET',
    });

const setTransactionBuyerRequest = (transactionId: number, buyerId: number) =>
    request<{ data: TransactionType }>(`/api/v1/transactions/${transactionId}/set-buyer`, {
        method: 'POST',
        data: { buyerId },
    });

const setTransactionDestinationRequest = (transactionId: number, destinationId: number) =>
    request<{ data: TransactionType }>(`/api/v1/transactions/${transactionId}/set-destination`, {
        method: 'POST',
        data: { destinationId },
    });

const setTransactionDeliveryOptionRequest = (
    transactionId: number,
    deliveryOption: DeliveryOptionTypes,
) =>
    request<{ data: TransactionType }>(
        `/api/v1/transactions/${transactionId}/set-delivery-option`,
        {
            method: 'POST',
            data: {
                deliveryOption,
            },
        },
    );

const completeTransactionRequest = (transactionId: number) =>
    request<{ data: TransactionType }>(`/api/v1/transactions/${transactionId}/complete`, {
        method: 'POST',
    });

const finishEditTransactionRequest = (transactionId: number) =>
    request<{ data: TransactionType }>(`/api/v1/transactions/${transactionId}/finish-edit`, {
        method: 'POST',
    });

const deleteTransactionRequest = (transactionId: number) =>
    request<{ data: {} }>(`/api/v1/transactions/${transactionId}`, {
        method: 'DELETE',
    });

// Thunks

export const setCurrentTransaction = (payload: TransactionType | null) => (dispatch: Dispatch) => {
    dispatch(actions.setCurrentTransaction(payload));
};

export const getAllTransactions = () => (dispatch: Dispatch) => {
    return getAllTransactionsRequest()
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            console.log('Error while getting all transaction request:', error);
            return error;
        });
};

export const getTransactions = () => (dispatch: Dispatch) => {
    return getTransactionsRequest()
        .then((response) => {
            if (response && response.data && response.data.data) {
                dispatch(actions.transactionsSuccess(response.data.data));
            } else {
                dispatch(actions.transactionsFail());
            }
        })
        .catch(() => {
            dispatch(actions.transactionsFail());
        });
};

export const createTransaction = () => (dispatch: Dispatch) => {
    return createTransactionRequest()
        .then((response) => {
            if (response && response.data && response.data.data) {
                return Promise.resolve(response.data.data);
            } else {
                return Promise.reject();
            }
        })
        .catch(() => {
            return Promise.reject();
        });
};

export const createTransactionFromBuyer = (supplierId: number) => (dispatch: Dispatch) => {
    return createTransactionFromBuyerRequest(supplierId)
        .then((response) => {
            if (response && response.data && response.data.data) {
                return Promise.resolve(response.data.data);
            } else {
                return Promise.reject();
            }
        })
        .catch(() => {
            return Promise.reject();
        });
};

export const updateTransaction = (transactionId: number, values: UpdateTransactionValuesType) => (
    dispatch: Dispatch,
) => {
    return updateTransactionRequest(transactionId, values)
        .then((response) => {
            if (response && response.data && response.data.transaction) {
                dispatch(actions.setCurrentTransaction(response.data.transaction));

                return Promise.resolve(response.data.transaction);
            } else {
                dispatch(actions.getTransactionError());

                return Promise.reject();
            }
        })
        .catch((error) => {
            dispatch(actions.getTransactionError());

            return Promise.reject();
        });
};

export const updateTransactionsVolumeDiscount = (userId: number) => () => {
    return updateTransactionsVolumeDiscountRequest(userId)
        .then((response) => {
            if (response && response.data && response.data) {

                return Promise.resolve(response.data);
            } else {
                return Promise.reject();
            }
        })
        .catch((error) => {
            console.log('error updating volume discount', error);
            return Promise.reject();
        });
};

export const getTransactionById = (transactionId: number) => (dispatch: Dispatch) => {
    return getTransactionRequest(transactionId)
        .then((response) => {
            if (response && response.data && response.data.data) {
                dispatch(actions.setCurrentTransaction(response.data.data));

                return Promise.resolve(response.data.data);
            } else {
                dispatch(actions.getTransactionError());

                return Promise.reject();
            }
        })
        .catch((error) => {
            dispatch(actions.getTransactionError());

            return Promise.reject();
        });
};

export const setTransactionBuyer = (transactionId: number, buyerId: number) => (
    dispatch: Dispatch,
) => {
    return setTransactionBuyerRequest(transactionId, buyerId);
};

export const deleteTransaction = (transactionId: number) => (dispatch: Dispatch) => {
    return deleteTransactionRequest(transactionId)
        .then(() => {
            dispatch(getUserTotals());
            dispatch(getTransactions());
            return Promise.resolve();
        })
        .catch((error) => {
            return Promise.reject();
        });
};

export const setTransactionDestination = (transactionId: number, destinationId: number) => (
    dispatch: Dispatch,
) => {
    return setTransactionDestinationRequest(transactionId, destinationId)
        .then((response) => {
            return dispatch(getTransactionById(transactionId));
        })
        .catch((error) => {
            return Promise.reject();
        });
};

export const setTransactionDeliveryOption = (
    transactionId: number,
    deliveryOption: DeliveryOptionTypes,
) => (dispatch: Dispatch) => {
    return setTransactionDeliveryOptionRequest(transactionId, deliveryOption)
        .then((response) => {
            return dispatch(getTransactionById(transactionId));
        })
        .catch((error) => {
            return Promise.reject();
        });
};

export const completeTransaction = (transactionId: number) => (dispatch: Dispatch) => {
    return completeTransactionRequest(transactionId)
        .then((response) => {
            dispatch(getUserTotals());
            return dispatch(getTransactionById(transactionId));
        })
        .catch((error) => {
            return Promise.reject();
        });
};

export const finishEditTransaction = (transactionId: number) => (dispatch: Dispatch) => {
    return finishEditTransactionRequest(transactionId)
        .then((response) => {
            dispatch(getUserTotals());
            return dispatch(getTransactionById(transactionId));
        })
        .catch((error) => {
            return Promise.reject();
        });
};

// Utils

export const getPoints = (user: UserType, transaction: TransactionType, points: number) => {
    if (isNewFlow) {
        return userOwnsTransaction(user, transaction) ? points : -1 * points;
    } else {
        return userOwnsTransaction(user, transaction) ? -1 * points : points;
    }
};

export const userOwnsTransaction = (user: UserType, transaction: TransactionType) => {
    if (!user || !transaction) {
        return false;
    }

    if (user.userType === 'dep') {
        return transaction.buyer && transaction.buyer.id === user.id;
    }
    if (user.userType === 'supplier') {
        return transaction.seller && transaction.seller.id === user.id;
    } else {
        return false;
    }
};

export const transactionTitle = (user: UserType, transaction: TransactionType) => {
    if (!user || !transaction) {
        return 'Transaction';
    }

    const incompleteMarker = transaction.status !== TransactionStatus.complete ? '**' : '';

    if (isNewFlow) {
        if (userOwnsTransaction(user, transaction)) {
            if (transaction.seller) {
                return `${transaction.seller.companyName} (#${transaction.id})${incompleteMarker}`;
            } else {
                return `Transaction #${transaction.id}${incompleteMarker}`;
            }
        }
        return `${transaction.buyer!.companyName} (#${transaction.id})${incompleteMarker}`;
    } else {
        if (userOwnsTransaction(user, transaction)) {
            if (transaction.buyer) {
                return `${transaction.buyer.companyName} (#${transaction.id})${incompleteMarker}`;
            } else {
                return `Transaction #${transaction.id}${incompleteMarker}`;
            }
        }

        return `${transaction.seller.companyName} (#${transaction.id})${incompleteMarker}`;
    }
};

export const isTransactionEditable = (user: UserType, transaction?: TransactionType) => {
    if (!user || !transaction) {
        return false;
    }

    // Only own transactions can be edited
    // Dep will be able to edit their transactions
    // Suppliers will be able to edit transactions created by them to differente deps
    return user.userType === 'dep' || user.userType === 'supplier'
        ? userOwnsTransaction(user, transaction)
        : transaction.status !== TransactionStatus.complete;
};
