import styled from 'styled-components';

export const ItemWrapper = styled.div`
    display: flex !important;
    outline: none;
`;

export const StyledLink = styled.div`
    width: 100%;
    padding: 15px;
    outline: none;
    text-decoration: none;
    color: #000;
    display: flex;

    > svg {
        margin-right: 20px;
    }
    align-items: center;

    cursor: pointer;
`;

export const MenuTitleWrapper = styled.div`
    padding: 0 15px;
    outline: none;
`;
