import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { FormattedMessage, injectIntl } from 'react-intl';
import colors from 'styles/colors';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { selectUser, logout } from 'services/authentication';
import { withRouter } from 'react-router-dom';
import * as Styles from './styles';
import popup from 'services/popup';
import { StyledLink } from './styles';
import { Divider } from 'styles/common';
import { Profile, Award, About, Clock, Home } from 'components/Icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

interface Props {}

export class SideMenu extends React.PureComponent<Props> {
    state = {
        menuOpen: false,
    };

    handleStateChange(state) {
        this.setState({ menuOpen: state.isOpen });
    }

    handleClickMenuItem = (route) => () => {
        if (route !== '/logout') {
            this.props.history.push(route);
            this.setState({
                menuOpen: false,
            });
        } else {
            this.props.hideMessage();
            this.props
                .logout()
                .then(() => {
                    window.location = '/login';
                })
                .catch((error) => {
                    console.log('Error logging out: ', error);

                    this.props.showError({
                        message: this.props.intl.formatMessage({ id: 'messages.genericError' }),
                    });
                });
        }
    };

    render() {
        if (this.props.user) {
            return (
                <Menu
                    styles={{
                        bmBurgerButton: {
                            position: 'fixed',
                            width: '30px',
                            height: '20px',
                            left: '20px',
                            top: '20px',
                        },
                        bmBurgerBars: {
                            background: colors.white,
                            height: '15%',
                        },
                        bmCrossButton: {
                            height: '24px',
                            width: '24px',
                            top: '22px',
                            right: '20px',
                        },
                        bmCross: {
                            background: '#bdc3c7',
                        },
                        bmMenuWrap: {
                            position: 'fixed',
                            height: '100%',
                        },
                        bmMenu: {
                            background: colors.white,
                            padding: '0',
                            fontSize: '1.15em',
                        },
                    }}
                    isOpen={this.state.menuOpen}
                    onStateChange={(state) => this.handleStateChange(state)}
                >
                    <Styles.MenuTitleWrapper>
                        {this.props.user.name ? <h3>{this.props.user.name}</h3> : <h3>Sobeys</h3>}
                    </Styles.MenuTitleWrapper>
                    <Divider />
                    <Styles.ItemWrapper>
                        <StyledLink
                            id="home"
                            className="menu-item"
                            onClick={this.handleClickMenuItem('/')}
                        >
                            <Home />
                            <FormattedMessage id="components.Menu.home" />
                        </StyledLink>
                    </Styles.ItemWrapper>
                    <Styles.ItemWrapper>
                        <StyledLink
                            id="profile"
                            className="menu-item"
                            onClick={this.handleClickMenuItem('/profile')}
                        >
                            <Profile />
                            <FormattedMessage id="components.Menu.profile" />
                        </StyledLink>
                    </Styles.ItemWrapper>
                    {/* <Styles.ItemWrapper>
                        <StyledLink
                            id="sponsors"
                            className="menu-item"
                            onClick={this.handleClickMenuItem('/sponsors')}
                        >
                            <Award />
                            <FormattedMessage id="components.Menu.sponsors" />
                        </StyledLink>
                    </Styles.ItemWrapper> */}
                    {/* <Styles.ItemWrapper>
                        <StyledLink
                            id="about"
                            className="menu-item"
                            onClick={this.handleClickMenuItem('/about')}
                        >
                            <About />
                            <FormattedMessage id="components.Menu.about" />
                        </StyledLink>
                    </Styles.ItemWrapper> */}
                    {/* <Styles.ItemWrapper>
                        <StyledLink
                            id="agenda"
                            className="menu-item"
                            onClick={this.handleClickMenuItem('/agenda')}
                        >
                            <Clock />
                            <FormattedMessage id="components.Menu.agenda" />
                        </StyledLink>
                    </Styles.ItemWrapper> */}
                    <Styles.ItemWrapper>
                        <StyledLink
                            id="logout"
                            className="menu-item"
                            onClick={this.handleClickMenuItem('/logout')}
                        >
                            <ExitToAppIcon />
                            <FormattedMessage id="components.Menu.logout" />
                        </StyledLink>
                    </Styles.ItemWrapper>
                </Menu>
            );
        }
        return null;
    }
}

const mapStateToProps = createStructuredSelector({
    user: selectUser(),
});

const mapDispatchToProps = {
    logout,
    showError: popup.actions.showError,
    hideMessage: popup.actions.hideMessage,
};

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(injectIntl(SideMenu));
