import { createGlobalStyle } from 'styled-components';

createGlobalStyle`
    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html,
    body {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    body {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        letter-spacing: 0.8px;
    }

    body.fontLoaded {
        font-family: 'Ubuntu', sans-serif;
    }

    #app {
        min-height: 100%;
        min-width: 100%;
    }
`;
