/*
 *
 * withAuthorization
 *
 * A higher order component that checks if a user is logged in or not. If the user is logged in
 * then the component will be rendered, otherwise the user will be redirected to the login page.
 */

import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Location } from 'history';

import { selectUser, selectError } from 'services/authentication';

import { UserType } from 'types';

interface Props {
    user?: UserType | null;
    location: Location;
}

const withAuthorization = (ComposedComponent: React.Component) => {
    class AuthorizedUser extends React.PureComponent<Props> {
        render(): React.ReactNode {
            // We want to ensure that redux store has time to populate before checking to redirect.
            if (localStorage.getItem('api_token') && !this.props.user && !this.props.error) {
                return null; // Can display a loading component in the future.
            }
            const currentPage = this.props.location.pathname;
            // Default Render
            return (
                <React.Fragment>
                    {this.props.user ? (
                        <ComposedComponent {...this.props} />
                    ) : (
                        <Redirect to={`/login?redirect=${currentPage}`} />
                    )}
                </React.Fragment>
            );
        }
    }
    const mapStateToProps = createStructuredSelector({
        user: selectUser(),
        error: selectError(),
    });

    return compose(
        withRouter,
        connect(mapStateToProps),
    )(AuthorizedUser);
};

export default withAuthorization;
