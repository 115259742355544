import { AxiosResponse, AxiosError } from 'axios';
import { UserType } from 'types';
import request from 'utils/request';
import { Dispatch } from 'redux';
import { createSlice } from 'redux-starter-kit';
import { createSelector } from 'reselect';

export type AdminSliceType = {
    users: Array<UserType>;
    error: string;
};

const initialState: AdminSliceType = {
    users: [],
    error: '',
};

// exports: actions, reducer, selectors, slice
const locations = createSlice({
    slice: 'admin',
    initialState,
    reducers: {
        usersSuccess(state, action) {
            state.users = action.payload;
            state.error = '';
        },
        usersFail(state, action) {
            state.users = [];
            state.error = 'Error';
        },
    },
});

const { selectors, actions } = locations;

export default locations;

export const selectUsers = () =>
    createSelector(
        [selectors.getAdmin],
        (substate) => substate.users,
    );

export const selectError = () =>
    createSelector(
        [selectors.getAdmin],
        (substate) => substate.error,
    );

// Requests
const getUsersRequest = () =>
    request<{ data: Array<UserType> }>(`/api/v1/users`, {
        method: 'GET',
    });

const getUserRequest = (userId: number) =>
    request<{ data: UserType }>(`/api/v1/users/${userId}`, {
        method: 'GET',
    });

const updateUserRequest = (user: UserType) =>
    request<{ data: UserType }>(`/api/v1/users/${user.id}`, {
        method: 'PUT',
        data: user,
    });

const createUserRequest = (user: UserType) =>
    request<{ data: UserType }>(`/api/v1/users`, {
        method: 'POST',
        data: user,
    });

// Thunks
export const getUsers = () => (dispatch: Dispatch) => {
    return getUsersRequest()
        .then((response) => {
            if (response && response.data && response.data.data) {
                dispatch(actions.usersSuccess(response.data.data));

                return Promise.resolve(response.data.data);
            } else {
                dispatch(actions.usersFail());

                return Promise.reject();
            }
        })
        .catch((error) => {
            dispatch(actions.usersFail());

            return Promise.reject(error);
        });
};

export const getUser = (userId: number) => (dispatch: Dispatch) => {
    return getUserRequest(userId)
        .then((response) => {
            if (response && response.data && response.data.data) {
                return Promise.resolve(response.data.data);
            } else {
                return Promise.reject();
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const updateUser = (user: UserType) => (dispatch: Dispatch) => {
    return updateUserRequest(user)
        .then((response) => {
            if (response && response.data && response.data.data) {
                return Promise.resolve(response.data.data);
            } else {
                return Promise.reject();
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const createUser = (user: UserType) => (dispatch: Dispatch) => {
    return createUserRequest(user)
        .then((response) => {
            if (response && response.data && response.data.data) {
                return Promise.resolve(response.data.data);
            } else {
                return Promise.reject();
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
