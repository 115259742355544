import axios, { AxiosResponse, AxiosError, AxiosRequestConfig, AxiosPromise } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

/**
 * Requests a URL, returning a promise.
 *
 * Axios by default will reject a promise if it is not between status codes 200-300
 * (This can be modified by implementing the validateStatus method)
 *
 * @param {string} url       The URL we want to request
 * @param {object} [options] The options we want to pass to "fetch"
 * @param {humps} [optional] Set to false to not camelize/decamelize keys
 *
 * @return {object}           An object containing either "data" or "err"
 */

export default function request<T>(
    url: string,
    options: { method?: string; data?: object } = {
        method: 'GET',
        data: {},
    },
    humps: boolean = false,
): AxiosPromise<T> {
    const config: AxiosRequestConfig = {
        url: `${process.env.API_URL}${url}`,
        method: options.method ? options.method : 'GET',
        data: humps ? decamelizeKeys(options.data || {}) : options.data,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
	timeout: 2 * 60 * 1000, // 2 minutes
    };

    const apiToken = localStorage.getItem('api_token');

    if (apiToken) {
        config.headers.Authorization = `Bearer ${apiToken}`;
    }

    return axios(config)
        .then((response: AxiosResponse<T>) => {
            response.data = humps ? (camelizeKeys(response.data) as T) : response.data;
            return Promise.resolve(response);
        })
        .catch((error: AxiosError) =>
            Promise.reject(humps ? camelizeKeys(error.response || {}) : error.response),
        );
}
