import React, { FC } from 'react';
// Styles
import { SVG } from '../styles';
// Types
import { ISVG } from '..';
export const About: FC<ISVG> = ({
    fill = 'currentColor',
    orientation = 'up',
    size = '24px',
    ...rest
}) => (
    <SVG
        role="img"
        {...rest}
        viewBox="0 0 24 24"
        width={size}
        height={size}
        orientation={orientation}
    >
        <path
            fill={fill}
            d="M17.92 0.11C18.3 0.01 18.7 0.1 19 0.34C19.31 0.57 19.49 0.93 19.5 1.32L19.5 13C19.5 13.57 19.12 14.08 18.56 14.22L11 16.16L11 1.89L17.92 0.11ZM1 0.34C1.3 0.11 1.69 0.02 2.06 0.11L9 1.89L9 16.16L1.46 14.21C0.91 14.07 0.52 13.57 0.52 13L0.52 1.34C0.52 0.95 0.69 0.58 1 0.34Z"
        />
    </SVG>
);
