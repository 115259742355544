import { createSlice, createSelector } from 'redux-starter-kit';
import { Dispatch } from 'react';

export type LanguageSliceType = {
    locale: string;
};

export const DEFAULT_LOCALE = 'fr';

const initialState: LanguageSliceType = {
    locale: localStorage.getItem('sobeys_locale') || DEFAULT_LOCALE,
};

// exports: actions, reducer, selectors, slice
const language = createSlice({
    slice: 'language',
    initialState,
    reducers: {
        changeLocale(state, action) {
            state.locale = action.payload;
        },
    },
});

export const selectLocale = () =>
    createSelector(
        [language.selectors.getLanguage],
        (substate) => substate.locale,
    );

export const setLocale = (locale: string) => (dispatch: Dispatch) => {
    localStorage.setItem('sobeys_locale', locale);
    dispatch(language.actions.changeLocale(locale));
};

export default language;
