import React, { FC } from 'react';
// Styles
import { SVG } from '../styles';
// Types
import { ISVG } from '..';

export const Profile: FC<ISVG> = ({
    fill = 'currentColor',
    orientation = 'up',
    size = '24px',
    ...rest
}) => (
    <SVG
        role="img"
        {...rest}
        viewBox="0 0 24 24"
        width={size}
        height={size}
        orientation={orientation}
    >
        <path
            fill={fill}
            d="m20 10c0 5.52-4.48 10-10 10s-10-4.48-10-10 4.48-10 10-10 10 4.48 10 10zm-13-4c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3zm9 7.98c-.03-1.99-4.01-3.08-6-3.08-2 0-5.97 1.09-6 3.08 1.29 1.94 3.5 3.22 6 3.22s4.71-1.28 6-3.22z"
        />
    </SVG>
);
