import styled from 'styled-components';
// Types
import { ISVG } from '.';
export const SVG = styled.svg<ISVG>(
    (props): any => {
        let rotation = '';
        switch (props.orientation) {
            default:
            case 'up':
                rotation = '0deg';
                break;
            case 'right':
                rotation = '90deg';
                break;
            case 'down':
                rotation = '180deg';
                break;
            case 'left':
                rotation = '270deg';
                break;
        }
        return {
            display: 'block',
            transform: `rotate(${rotation})`,
            transition: 'all ease 0.2s',
        };
    },
);
