import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from '../../styles/colors';

export const Header = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    height: 60px;
    background-color: ${colors.primary};
    padding: 0 40px;
    z-index: 500;
`;

export const LeftContainer = styled.div`
    flex: 1;
`;

export const MiddleContainer = styled.div`
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const RightContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const TitleText = styled.h1`
    font-size: 19px;
    line-height: 24px;
    text-align: center;
    font-size: 19px;
    font-weight: bold;
    color: ${colors.white};
`;
