import React, { FC } from 'react';
// Styles
import { SVG } from '../styles';
// Types
import { ISVG } from '..';
export const Award: FC<ISVG> = ({
    fill = 'currentColor',
    orientation = 'up',
    size = '24px',
    ...rest
}) => (
    <SVG
        role="img"
        {...rest}
        viewBox="0 0 24 24"
        width={size}
        height={size}
        orientation={orientation}
    >
        <path
            fill={fill}
            d="M14 18.75C14.08 19.05 14.01 19.38 13.81 19.63C13.62 19.87 13.31 20.01 13 20C12.82 20.01 12.64 19.96 12.49 19.87L6.81 16.73L1.48 19.86C1.13 20.07 0.69 20.04 0.37 19.8C0.04 19.56 -0.1 19.14 0 18.75L2.34 9.77C1.47 8.71 1 7.37 1 6C1 2.69 3.69 0 7 0C10.31 0 13 2.69 13 6C13.01 7.36 12.54 8.69 11.69 9.75L14 18.75ZM3 6C3 8.21 4.79 10 7 10C9.21 10 11 8.21 11 6C11 3.79 9.21 2 7 2C4.79 2 3 3.79 3 6Z"
        />
    </SVG>
);
