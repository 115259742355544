import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as Sentry from '@sentry/browser';
import { History } from 'history';

import withUser from 'containers/withUser';
import Header from 'components/Header';

import Routes from 'routes';

import { selectUser } from 'services/authentication';
import language, { selectLocale } from 'services/language';

import { AppWrapper } from 'styles/common';
import SnackbarComponent from 'components/Snackbar';
import { InjectedIntl, injectIntl } from 'react-intl';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import colors from 'styles/colors';

interface Props {
    history: History;
    locale: string;
    user?: any;
    intl: InjectedIntl;
}

class App extends React.PureComponent<Props> {
    componentDidCatch(error: Error | null, errorInfo: object) {
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    checkPathIncludes = (shortPath: string) => {
        return this.props.location.pathname.includes(shortPath);
    };

    getHeaderTitle = () => {
        if (this.checkPathIncludes('/profile')) {
            return 'containers.ProfilePage.helmetTitle';
        } else if (this.checkPathIncludes('/transaction')) {
            return 'containers.TransactionPage.helmetTitle';
        } else if (this.checkPathIncludes('/sponsors')) {
            return 'containers.SponsorsPage.helmetTitle';
        } else if (this.checkPathIncludes('/about')) {
            return 'containers.About.helmetTitle';
        } else if (this.checkPathIncludes('/agenda')) {
            return 'containers.Agenda.helmetTitle';
        }
        return 'sobeys';
    };

    renderSnackbar = () => <SnackbarComponent />;

    theme = createTheme({
        palette: {
            action: {
                disabledBackground: `${colors.green}50`,
                disabled: colors.white,
            },
            primary: {
                main: colors.green,
            },
            secondary: {
                main: colors.red,
            },
        },
    });

    render() {
        return (
            <ThemeProvider theme={this.theme}>
                <AppWrapper>
                    <Helmet titleTemplate="%s - Sobeys" defaultTitle="Sobeys" />
                    <Header
                        title={this.props.intl.formatMessage({
                            id: this.getHeaderTitle(),
                        })}
                    />
                    <Routes />
                </AppWrapper>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    locale: selectLocale(),
    user: selectUser(),
});

const mapDispatchToProps = {};

export default compose(
    withUser,
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(injectIntl(App));
