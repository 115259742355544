import React from 'react';
import { injectIntl } from 'react-intl';

import popup, { selectVisible, selectMessage, selectVariant } from 'services/popup';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as Styles from './styles';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

interface Props {
    snackbarMessage: string;
    snackbarVariant: 'error' | 'warning' | 'success' | 'info';
    snackbarVisible: boolean;
    hideMessage: () => void;
}

function SnackbarComponent(props: Props) {
    const handleClickSnackbarClose = (event: React.SyntheticEvent<Element, Event>) => {
        props.hideMessage();
    };

    if (!props.snackbarVisible) {
        return null;
    }

    return (
        <Styles.Wrapper>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={props.snackbarVisible}
                autoHideDuration={3000}
                onClose={handleClickSnackbarClose}
            >
                <MuiAlert
                    elevation={100}
                    variant="filled"
                    {...props}
                    onClose={handleClickSnackbarClose}
                    severity={props.snackbarVariant}
                >
                    {props.snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Styles.Wrapper>
    );
}

const mapStateToProps = createStructuredSelector({
    snackbarMessage: selectMessage(),
    snackbarVariant: selectVariant(),
    snackbarVisible: selectVisible(),
});

const mapDispatchToProps = {
    hideMessage: popup.actions.hideMessage,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    injectIntl,
)(SnackbarComponent);
