import { createSlice } from 'redux-starter-kit';

export type CounterSliceType = number;

const initialState = 0;
// actions, reducer, selectors, slice
const counter = createSlice({
    slice: 'counter', // slice is optional, and could be blank ''
    initialState,
    reducers: {
        increment: (state) => state + 1,
        decrement: (state) => state - 1,
        multiply: (state, action) => state * action.payload,
    },
});

// actions, reducers,
export default counter;
