/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { addLocaleData } from 'react-intl';
import flatten from 'flat';

import enLocaleData from 'react-intl/locale-data/en';
import frLocaleData from 'react-intl/locale-data/fr';
import { DEFAULT_LOCALE } from './services/language';

import enTranslationMessages from './translations/en.json';
import frTranslationMessages from './translations/fr.json';

export const appLocales = ['en', 'fr'];

addLocaleData(enLocaleData);
addLocaleData(frLocaleData);

interface Messages {
    [key: string]: string;
}

export const formatTranslationMessages = (locale: string, messages: Messages) => {
    const defaultFormattedMessages =
        locale !== DEFAULT_LOCALE
            ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
            : {};
    const formattedMessages: Messages = {};
    const messageKeys = Object.keys(messages);

    for (const messageKey of messageKeys) {
        if (locale === DEFAULT_LOCALE) {
            formattedMessages[messageKey] = messages[messageKey];
        } else {
            formattedMessages[messageKey] =
                messages[messageKey] || defaultFormattedMessages[messageKey];
        }
    }

    return formattedMessages;
};

export const translationMessages = {
    en: formatTranslationMessages('en', flatten(enTranslationMessages)),
    fr: formatTranslationMessages('fr', flatten(frTranslationMessages)),
};
