import styled from 'styled-components';
import colors from './colors';
import screen from './screen';

export const AppWrapper = styled.div`
    width: 100%;
    background-color: ${colors.white};
    margin: 0 auto;
    min-height: 100%;
    padding-top: 60px;
    padding-bottom: 75px;
    position: relative;
`;

export const Container = styled.div`
    color: ${colors.gray10};
    width: ${screen.large};
    max-width: 100%;
    margin: auto;
    display: flex;
`;

export const InnerContainer = styled.div`
    padding: 20px 20px;
    width: 100%;
`;

export const Content = styled.div`
    padding: 50px 35px;

    h1:first-child {
        margin-top: 0;
    }
`;

export const BottomButtonWrapper = styled.div`
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 78%,
        rgba(255, 255, 255, 0.7553396358543417) 100%
    );
    padding: 20px;

    width: 100%;
    justify-content: center;

    @media only screen and (max-height: 400px) {
        position: absolute;
    }
`;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${colors.gray90};
`;

export const SmallHeader = styled.h2`
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 24px;
    ${(props) => {
        if (props.display) {
            return `display: ${props.display}`;
        }

        return '';
    }}
`;

export const WrapperWithBottomBorder = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 0px;
    border-bottom: 1px solid ${colors.gray90};
`;

export const TitleAndButtonContainer = styled.div`
    > h2 {
        margin-top: 0 5px;
    }
    padding-top: 30px;
`;

export const TopTitle = styled.h3`
    color: ${colors.gray40};
    margin-bottom: 35px;
`;

export const RegularText = styled.span`
    font-size: 16px;
    line-height: 24px;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Loading = styled.img`
    width: 2rem;
    margin-bottom: 3rem;
    height: 2rem;
`;

export const LoadingContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
`;
