import { AxiosResponse, AxiosError } from 'axios';
import { LocationType, UserType } from 'types';
import request from 'utils/request';
import { Dispatch } from 'redux';
import { createSlice } from 'redux-starter-kit';
import { createSelector } from 'reselect';

export type LocationsSliceType = {
    currentBuyerUser: UserType;
    error: string;
};

const initialState: LocationsSliceType = {
    currentBuyerUser: null,
    error: '',
};

// exports: actions, reducer, selectors, slice
const locations = createSlice({
    slice: 'locations',
    initialState,
    reducers: {
        currentBuyerUserSuccess(state, action) {
            state.currentBuyerUser = action.payload;
            state.error = '';
        },
        currentBuyerUserFail(state, action) {
            state.currentBuyerUser = null;
            state.error = 'Error';
        },
        resetCurrentBuyerUser(state, action) {
            state.currentBuyerUser = null;
            state.error = '';
        },
    },
});

const { selectors, actions } = locations;

export default locations;

export const selectCurrentBuyerUser = () =>
    createSelector(
        [selectors.getLocations],
        (substate) => substate.currentBuyerUser,
    );

export const selectCurrentBuyerUserLocationOptions = () =>
    createSelector(
        [selectCurrentBuyerUser()],
        (currentBuyerUser) => {
            if (
                currentBuyerUser &&
                currentBuyerUser.locations &&
                currentBuyerUser.locations.length
            ) {
                return currentBuyerUser.locations.map((location) => ({
                    label: locationName(location),
                    value: location.id,
                }));
            }

            return [];
        },
    );

// Requests
const getUserLocationsRequest = (userId: number) =>
    request<{ data: Array<LocationType> }>(`/api/v1/users/${userId}/locations`, {
        method: 'GET',
    });

const createLocationRequest = (location: LocationType) =>
    request<{ data: Array<LocationType> }>(`/api/v1/locations`, {
        method: 'POST',
        data: location,
    });

const updateLocationRequest = (location: LocationType) =>
    request<{ data: Array<LocationType> }>(`/api/v1/locations/${location.id}`, {
        method: 'PUT',
        data: location,
    });

const getUserLocationBySiteNumberRequest = (siteNumber: number) =>
    request<{ data: Array<LocationType> }>(`/api/v1/locations/siteNumber/${siteNumber}`, {
        method: 'GET',
    });

// Thunks
export const getUserLocations = (userId: number) => (dispatch: Dispatch) => {
    dispatch(actions.resetCurrentBuyerUser());

    return getUserLocationsRequest(userId)
        .then((response) => {
            if (response && response.data && response.data.data) {
                dispatch(actions.currentBuyerUserSuccess(response.data.data));
            } else {
                dispatch(actions.currentBuyerUserFail());
            }
        })
        .catch((error) => {
            dispatch(actions.currentBuyerUserFail());
        });
};

export const createLocation = (location: LocationType) => (dispatch: Dispatch) => {
    return createLocationRequest(location);
};

export const updateLocation = (location: LocationType) => (dispatch: Dispatch) => {
    return updateLocationRequest(location);
};

export const getLocationBySiteNumber = (siteNumber: number) => (dispatch: Dispatch) => {
    return getUserLocationBySiteNumberRequest(siteNumber);
};

// Utils
export const locationName = (location: LocationType) =>
    `${location.address} (${location.siteNumber})`;
