import { AxiosResponse, AxiosError } from 'axios';
import { LocationType, UserType, TypeOfUser } from 'types';
import request from 'utils/request';
import { Dispatch } from 'redux';
import { createSlice } from 'redux-starter-kit';
import { createSelector } from 'reselect';

export type SupplierSliceType = {
    suppliers: UserType[];
    selectedSupplier: UserType | null;
    error: string;
};

const initialState: SupplierSliceType = {
    suppliers: [],
    selectedSupplier: null,
    error: '',
};

// exports: actions, reducer, selectors, slice
const suppliers = createSlice({
    slice: 'suppliers',
    initialState,
    reducers: {
        suppliersSuccess(state, action) {
            state.suppliers = action.payload;
            state.error = '';
        },
        suppliersFail(state) {
            state.suppliers = [];
            state.error = 'Error';
        },
        selectSupplierSuccess(state, action) {
            state.selectedSupplier = action.payload;
            state.error = '';
        },
        selectSupplierFail(state, action) {
            state.selectedSupplier = null;
            state.error = 'Error';
        },
    },
});

const { selectors, actions } = suppliers;

export default suppliers;

export const selectSuppliers = () =>
    createSelector(
        [selectors.getSuppliers],
        (substate) => substate.suppliers,
    );

export const selectSelectedSupplier = () =>
    createSelector(
        [selectors.getSuppliers],
        (substate) => substate.selectedSupplier,
    );

// Requests
const getAllSuppliersRequest = () =>
    request<{ data: Array<LocationType> }>(`/api/v1/users/type/supplier`, {
        method: 'GET',
    });

const getBuyerRequest = (userId: number) =>
    request<{ data: UserType }>(`/api/v1/users/buyer-info/${userId}`, {
        method: 'GET',
    });

export const getAllSuppliers = () => (dispatch: Dispatch) => {
    return getAllSuppliersRequest()
        .then((response) => {
            if (response && response.data && response.data.data) {
                dispatch(actions.suppliersSuccess(response.data.data));
            } else {
                dispatch(actions.suppliersFail());
            }
        })
        .catch((error) => {
            dispatch(actions.suppliersFail());
        });
};
export const setSupplier = (supplier: UserType) => (dispatch: Dispatch) => {
    dispatch(actions.selectSupplierSuccess(supplier));
};

export const getBuyer = (userId: number) => (dispatch: Dispatch) => {
    return getBuyerRequest(userId)
        .then((response) => {
            if (response && response.data && response.data.data) {
                return Promise.resolve(response.data.data);
            } else {
                return Promise.reject();
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
