import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/browser';

import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';

import configureStore from './store';
import { translationMessages } from './i18n';

// Global styles
import 'sanitize.css/sanitize.css';
import './styles/global';
import Menu from 'components/Menu';

// Create redux store with history
const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);

const MOUNT_NODE = document.getElementById('app');

// Error handling with Sentry.io
if (process.env.SENTRY_DSN && __PROD__) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
    });
}

// TODO: Check if need to do special hot reloading for i18n files (like in react-boilerplate)
const renderApp = (messages: object) => {
    ReactDOM.render(
        <Provider store={store}>
            <LanguageProvider messages={messages}>
                <ConnectedRouter history={history}>
                    <Menu />
                    <App />
                </ConnectedRouter>
            </LanguageProvider>
        </Provider>,
        MOUNT_NODE,
    );
};

renderApp(translationMessages);

if (module.hot) {
    module.hot.accept('containers/App', () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        renderApp(translationMessages);
    });
}
