export interface ISVG {
    /**
     * Defines the color of the icon
     */
    fill?: string;
    /**
     * Sets the size (width & height) of icon
     */
    size?: string;
    /**
     * Optional style prop to append to component
     */
    styles?: object;
    /**
     * Optional orientation prop to rotate the icon
     */
    orientation?: 'up' | 'down' | 'left' | 'right';
}

export { Profile } from './profile';
export { Award } from './award';
export { About } from './about';
export { Clock } from './clock';
export { Home } from './home';
