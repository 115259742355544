/*
 *
 * withUser
 *
 * A higher order component that checks gets for a user after a page has been reloaded.
 * It checks the local storage for an API Token and then makes an authentication request
 * to see if the user is logged in or not. Once the check is complete, the app gets rendered.
 * The reason for checking for the user login status prior to loading the page is so that the
 * header correctly shows either "login" or "logout" on first page load.
 */

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { History } from 'history';

import { selectUser, whoami } from 'services/authentication';

interface Props {
    // authenticateUser: (code: string) => Promise<any>;
    history: History<any>;
    user?: object;
    whoami: () => void;
}

const withUser = (ComposedComponent: React.Component) => {
    class UserInformation extends React.Component<Props> {
        componentDidMount() {
            this.checkUser();
        }

        checkUser = () => {
            const API_TOKEN = localStorage.getItem('api_token');

            if (API_TOKEN) {
                this.props.whoami();
            }
        };

        render() {
            return (
                <React.Fragment>
                    <ComposedComponent {...this.props} />
                </React.Fragment>
            );
        }
    }
    const mapStateToProps = createStructuredSelector({
        user: selectUser(),
    });

    const mapDispatchToProps = {
        // authenticateUser,
        whoami,
    };

    return compose(
        withRouter,
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
    )(UserInformation);
};

export default withUser;
