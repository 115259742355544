import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as Header from './styles';
import SnackbarComponent from 'components/Snackbar';

interface Props {
    title: string;
}

function HeaderComponent(props: Props) {
    return (
        <>
            <Header.Header>
                <Header.LeftContainer />
                <Header.MiddleContainer>
                    <Header.TitleText>{props.title}</Header.TitleText>
                </Header.MiddleContainer>
                <Header.RightContainer />
            </Header.Header>
            <SnackbarComponent />
        </>
    );
}

export default HeaderComponent;
