import React, { FC } from 'react';
// Styles
import { SVG } from '../styles';
// Types
import { ISVG } from '..';
export const Home: FC<ISVG> = ({
    fill = 'currentColor',
    orientation = 'up',
    size = '24px',
    ...rest
}) => (
    <SVG
        role="img"
        {...rest}
        viewBox="0 0 24 24"
        width={size}
        height={size}
        orientation={orientation}
    >
        <path
            fill={fill}
            d="M3 9L12 2L21 9L21 20C21 20.26 20.95 20.52 20.85 20.77C20.75 21.01 20.6 21.23 20.41 21.41C20.23 21.6 20.01 21.75 19.77 21.85C19.52 21.95 19.26 22 19 22L5 22C4.74 22 4.48 21.95 4.23 21.85C3.99 21.75 3.77 21.6 3.59 21.41C3.4 21.23 3.25 21.01 3.15 20.77C3.05 20.52 3 20.26 3 20L3 9Z"
        />
    </SVG>
);
