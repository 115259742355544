import React, { FC } from 'react';
// Styles
import { SVG } from '../styles';
// Types
import { ISVG } from '..';
export const Clock: FC<ISVG> = ({
    fill = 'currentColor',
    orientation = 'up',
    size = '24px',
    ...rest
}) => (
    <SVG
        role="img"
        {...rest}
        viewBox="0 0 24 24"
        width={size}
        height={size}
        orientation={orientation}
    >
        <path
            fill={fill}
            d="M17.07 2.93C18.95 4.8 20 7.35 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0C12.65 0 15.2 1.05 17.07 2.93ZM15 10C15 9.45 14.55 9 14 9L11 9L11 6C11 5.45 10.55 5 10 5C9.45 5 9 5.45 9 6L9 10C9 10.55 9.45 11 10 11L14 11C14.55 11 15 10.55 15 10Z"
        />
    </SVG>
);
