import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { injectIntl, InjectedIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { selectUser, selectError } from 'services/authentication';

interface Props extends RouteComponentProps {
    intl: InjectedIntl;
}

export class HomePageComponent extends React.PureComponent<Props> {
    componentDidMount() {}

    render() {
        if (localStorage.getItem('api_token') && !this.props.user && !this.props.error) {
            return null;
        }

        let redirectTo = '/login';

        if (this.props.user && this.props.user.role === 'admin') {
            redirectTo = '/admin';
        } else if (this.props.user) {
            redirectTo = '/dashboard';
        }

        return (
            <React.Fragment>
                <Redirect to={redirectTo} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    user: selectUser(),
    error: selectError(),
});

const mapDispatchToProps = {};

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    injectIntl,
)(HomePageComponent);
