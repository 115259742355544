import { Dispatch } from 'redux';
import { createSlice, createSelector } from 'redux-starter-kit';
import { AxiosError } from 'axios';
import { UserTotalsType, UserType } from 'types';
import request from 'utils/request';

export type AuthenticationSliceType = {
    user: UserType | null;
    error: string;

    totalPoints: number | null;
    totalSavings: number | null;
    totalPrice: number | null;
};

const initialState: AuthenticationSliceType = {
    user: null,
    error: '',
    totalPoints: null,
    totalSavings: null,
    totalPrice: null,
};

// exports: actions, reducer, selectors, slice
const authentication = createSlice({
    slice: 'authentication',
    initialState,
    reducers: {
        authSuccess(state, action) {
            state.user = action.payload;
            state.error = '';
            state.totalPoints = null;
            state.totalSavings = null;
            state.totalPrice = null;
        },
        authFail(state, action) {
            state.user = null;
            state.error = action.payload ? action.payload.error : 'Error';
            state.totalPoints = null;
            state.totalSavings = null;
            state.totalPrice = null;
        },
        logout(state) {
            state.user = null;
            state.error = '';
            state.totalPoints = null;
            state.totalSavings = null;
            state.totalPrice = null;
        },
        savingsPointsSuccess(state, action) {
            state.totalPoints = action.payload.totalPoints;
            state.totalSavings = action.payload.totalSavings;
            state.totalPrice = action.payload.totalPrice;
        },
        savingsPointsError(state) {
            state.totalPoints = null;
            state.totalSavings = null;
            state.totalPrice = null;
        },
    },
});

export default authentication;

const { actions, selectors } = authentication;

// Additional selectors
export const selectUser = () =>
    createSelector(
        [selectors.getAuthentication],
        (authState) => authState.user,
    );

export const selectError = () =>
    createSelector(
        [selectors.getAuthentication],
        (authState) => authState.error,
    );

export const selectUserProducts = () =>
    createSelector(
        [selectUser()],
        (user) => user && user.products,
    );

export const selectUserPoints = () =>
    createSelector(
        [selectors.getAuthentication],
        (authState) => authState.totalPoints,
    );

export const selectUserSavings = () =>
    createSelector(
        [selectors.getAuthentication],
        (authState) => authState.totalSavings,
    );

export const selectUserTotal = () =>
    createSelector(
        [selectors.getAuthentication],
        (authState) => authState.totalPrice,
    );

// Requests
export const loginRequest = (email: string, password: string) =>
    request<{ data: { user: UserType; token: string } }>(`/api/v1/auth/login`, {
        method: 'POST',
        data: { email, password },
    });

export const whoamiRequest = () => request<{ data: UserType }>(`/api/v1/auth/whoami`);

export const logoutRequest = () => request(`/api/v1/auth/logout`, { method: 'POST' });

export const getUserTotalsRequest = () =>
    request<{ data: UserTotalsType }>(`/api/v1/users/me/my-totals`, {
        method: 'GET',
    });

// Thunks
export const whoami = () => (dispatch: Dispatch) =>
    whoamiRequest()
        .then((response) => {
            dispatch(actions.authSuccess(response.data.data));
            dispatch(getUserTotals());

            return Promise.resolve(response.data.data);
        })
        .catch((error: AxiosError) => {
            localStorage.removeItem('api_token');
            dispatch(actions.authFail(error.response));

            return Promise.reject(error);
        });

export const login = (email: string, password: string) => (dispatch: Dispatch) => {
    return loginRequest(email, password).then((response) => {
        if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.token &&
            response.data.data.token.length
        ) {
            localStorage.setItem('api_token', response.data.data.token);
            return dispatch(whoami());
        }
        throw Error('User token not available.');
    });
};

export const getUserTotals = () => (dispatch: Dispatch) => {
    return getUserTotalsRequest()
        .then((response) => {
            if (response && response.data && response.data.data) {
                dispatch(actions.savingsPointsSuccess(response.data.data));

                return Promise.resolve(response.data.data);
            } else {
                dispatch(actions.savingsPointsError());

                return Promise.reject();
            }
        })
        .catch((error) => {
            dispatch(actions.savingsPointsError());

            return Promise.reject();
        });
};

export const logout = () => (dispatch: Dispatch) => {
    return new Promise((resolve) => {
        logoutRequest();
        localStorage.removeItem('api_token');
        dispatch(actions.logout());
        resolve();
    });
};
