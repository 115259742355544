import { combineReducers } from 'redux';
import { History } from 'history';
import { RouterState, connectRouter } from 'connected-react-router';

import authentication from 'services/authentication';
import counter from 'services/counter';
import language from 'services/language';
import transactions from 'services/transactions';
import items from 'services/items';
import locations from 'services/locations';
import admin from 'services/admin';
import popup from 'services/popup';
import suppliers from 'services/suppliers';

const rootReducer = (history: History<any>) =>
    combineReducers({
        router: connectRouter(history),
        authentication: authentication.reducer,
        counter: counter.reducer,
        language: language.reducer,
        transactions: transactions.reducer,
        items: items.reducer,
        locations: locations.reducer,
        popup: popup.reducer,
        admin: admin.reducer,
        suppliers: suppliers.reducer,
        // Insert reducers here
    });

export interface State {
    router: RouterState;
}

export default rootReducer;
