import { createSlice } from 'redux-starter-kit';
import { createSelector } from 'reselect';
import { Dispatch } from 'react';

export type PopupSliceType = {
    visible: boolean;
    variant: 'error' | 'warning' | 'success' | 'info';
    message: string;
    timestamp: number;
};

const initialState: PopupSliceType = {
    visible: false,
    variant: 'error',
    message: '',
    timestamp: Date.now(),
};

// actions, reducer, selectors, slice
const popup = createSlice({
    slice: 'popup',
    initialState,
    reducers: {
        showSuccess: (state, action) => {
            state.visible = true;
            state.message = action.payload.message;
            state.variant = 'success';
            state.timestamp = Date.now();
        },
        showError: (state, action) => {
            state.visible = true;
            state.message = action.payload.message;
            state.variant = 'error';
            state.timestamp = Date.now();
        },
        hideMessage: (state, action) => {
            state.visible = false;
            state.message = '';
            state.variant = 'error';
            state.timestamp = Date.now();
        },
    },
});

export const selectVisible = () =>
    createSelector(
        [popup.selectors.getPopup],
        (subState) => subState.visible,
    );

export const selectMessage = () =>
    createSelector(
        [popup.selectors.getPopup],
        (subState) => subState.message,
    );

export const selectVariant = () =>
    createSelector(
        [popup.selectors.getPopup],
        (subState) => subState.variant,
    );

const selectTimestamp = () =>
    createSelector(
        [popup.selectors.getPopup],
        (subState) => subState.timestamp,
    );

export const showSuccessAndHide = (message: string) => (dispatch: Dispatch, getState) => {
    dispatch(popup.actions.showSuccess({ message }));
    const messageTimestamp = selectTimestamp()(getState());

    setTimeout(() => {
        const currentMessageTimestamp = selectTimestamp()(getState());

        if (currentMessageTimestamp === messageTimestamp) {
            dispatch(popup.actions.hideMessage());
        }
    }, 3000);
};

// actions, reducers,
export default popup;
