import { configureStore, getDefaultMiddleware } from 'redux-starter-kit';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import rootReducer from './reducers';

const store = (preloadedState = {}, history: History<any>) => {
    let middleware = [];

    if (__PROD__) {
        middleware = [...getDefaultMiddleware(), routerMiddleware(history)];
    } else {
        middleware = [...getDefaultMiddleware(), routerMiddleware(history)];
    }

    return configureStore({
        reducer: rootReducer(history),
        middleware,
        devTools: __DEV__,
        preloadedState,
        enhancers: [],
    });
};

export default store;
