import { Dispatch } from 'redux';
import { createSlice, createSelector } from 'redux-starter-kit';
import { UserType, TransactionType, ItemType } from 'types';
import request from 'utils/request';
import { getPoints } from './transactions';

export type ItemsSliceType = {
    currentItem: ItemType;
    error: string;
};

const initialState: ItemsSliceType = {
    currentItem: null,
    error: '',
};

// exports: actions, reducer, selectors, slice
const items = createSlice({
    slice: 'items',
    initialState,
    reducers: {
        currentItemSuccess(state, action) {
            state.currentItem = action.payload;
            state.error = '';
        },
        currentItemFail(state, action) {
            state.currentItem = null;
            state.error = 'Error';
        },
        itemsSuccess(state, action) {
            state.error = '';
        },
        itemsDeleteSuccess(state, action) {
            state.error = '';
            if (state.currentItem && state.currentItem.id === action.payload.id) {
                state.currentItem = null;
            }
        },
        itemsFail(state, action) {
            state.error = 'Error';
        },
    },
});

export default items;

const { actions, selectors } = items;

// Additional selectors
export const selectCurrentItem = () =>
    createSelector(
        [selectors.getItems],
        (subState) => subState.currentItem,
    );

export const selectError = () =>
    createSelector(
        [selectors.getItems],
        (subState) => subState.error,
    );

// Requests
const getItemRequest = (id: number) =>
    request<{ data: ItemType }>(`/api/v1/items/${id}`, {
        method: 'GET',
    });

const createItemRequest = (item: ItemType) =>
    request<{ data: ItemType }>(`/api/v1/items`, {
        method: 'POST',
        data: item,
    });

const updateItemRequest = (item: ItemType) =>
    request<{ data: ItemType }>(`/api/v1/items/${item.id}`, {
        method: 'PUT',
        data: item,
    });

const deleteItemRequest = (item: ItemType) =>
    request<{ data: ItemType }>(`/api/v1/items/${item.id}`, {
        method: 'DELETE',
    });

// Thunks
export const getItemById = (id: number) => (dispatch: Dispatch) => {
    return getItemRequest(id)
        .then((response) => {
            dispatch(actions.currentItemSuccess(response.data.data));

            return Promise.resolve(response.data.data);
        })
        .catch((error) => {
            dispatch(actions.currentItemFail());

            return Promise.reject();
        });
};

export const createItem = (item: ItemType) => (dispatch: Dispatch) => {
    return createItemRequest(item)
        .then((response) => {
            dispatch(actions.itemsSuccess(response.data.data));

            return Promise.resolve(response.data.data);
        })
        .catch((error) => {
            dispatch(actions.itemsFail());

            return Promise.reject();
        });
};

export const updateItem = (item: ItemType) => (dispatch: Dispatch) => {
    return updateItemRequest(item)
        .then((response) => {
            dispatch(actions.itemsSuccess(response.data.data));

            return Promise.resolve(response.data.data);
        })
        .catch((error) => {
            dispatch(actions.itemsFail());

            return Promise.reject();
        });
};

export const deleteItem = (item: ItemType) => (dispatch: Dispatch) => {
    return deleteItemRequest(item)
        .then((response) => {
            dispatch(actions.itemsDeleteSuccess(item));

            return Promise.resolve();
        })
        .catch((error) => {
            dispatch(actions.itemsFail());

            return Promise.reject();
        });
};

// Utils
export const itemDescription = (i: ItemType) =>
    `${i.product ? i.product.description : i.description}`;

export const itemTitle = (i: ItemType) => `${i.quantity}x ${itemDescription(i)}`;

export const itemSubtitle = (i: ItemType) => `${i.product ? i.product.upc : ''}`;

export const itemPointsAbsolute = (item: ItemType) =>
    item.product ? item.product.points * item.quantity + item.extraPoints : item.extraPoints;

export const itemPoints = (user: UserType, transaction: TransactionType, item: ItemType) => {
    if (!user || !transaction || !item) {
        return 0;
    }

    return getPoints(user, transaction, itemPointsAbsolute(item));
};

export const isEmptyItem = (i: ItemType) => !i.description && !i.extraPoints && !i.product;
