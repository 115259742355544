const colors = {
    // Grayscale
    black: '#000000',
    black60percent: 'rgba(0,0,0,0.6)',
    black87percent: 'rgba(0,0,0,0.87)',
    gray10: '#1a1a1a',
    gray20: '#333333',
    gray30: '#4d4d4d',
    gray40: '#666666',
    gray50: '#7f7f7f',
    gray60: '#999999',
    gray70: '#b3b3b3',
    gray80: '#cccccc',
    gray90: '#e5e5e5',
    grayEF: '#efefef',
    white: '#ffffff',
    primary: '#48A548',
    green: '#40A239',
    red: '#ff0000',
};

export default colors;
