import { DELIVERY_OPTIONS } from 'utils/constants';

// declare const __DEV__: boolean;
declare const __PROD__: boolean;

export type TypeOfUser = 'supplier' | 'dep' | 'perk';

export type TypeOfPriceType = 'A' | 'B' | 'none';

export type DeliveryOptionTypes = keyof typeof DELIVERY_OPTIONS;

export type UserTotalsType = {
    totalPoints: number;
    totalSavings: number;
};

export type UserType = {
    id: number;
    email: string;
    role: 'admin' | 'user';
    companyName: string;
    phone: string;
    name: string;
    supplierNumber: string;
    supplyType: 'entrepot' | 'direct';
    userType: TypeOfUser;
    locations: LocationType[];
    products: ProductType[];
    promotions: PromotionType[];
    priceType: TypeOfPriceType;
};

export type PromotionType = {
    id: number;
    description: string;
    isSupplierLevelPromotion: boolean;
    maxQuantity: number | null;
    minQuantity: number | null;
    moduloQuantity: number | null;
    pointsPerQuantity: number;
    savingsPerQuantity: number;
    supplierId: number;
    applicableProducts: ProductType[];
    classPromo?: boolean | number;
};

export type LocationType = {
    id: number;
    siteNumber: string;
    banner: string;
    address: string;
    city: string;
    phone: string;
};

export type ProductType = {
    id: number;
    upc: string;
    description: string;
    imageUrl: string;
    regularPriceA: number;
    netPriceA: number;
    savingsA: number;
    isPointsA: boolean;
    suggestedQuantity: number | null;
    unitPoints: number | null;
    regularPriceB: number;
    netPriceB: number;
    savingsB: number;
    isPointsB: boolean;
};

export type TransactionType = {
    id: number;
    destination?: LocationType;
    deliveryOption?: DeliveryOptionTypes;
    buyer?: UserType;
    seller: UserType;
    status: TransactionStatus;
    items: Array<ItemType>;
    totalPoints: number | null;
    totalSavings: number | null;
    totalPrice: number | null;
    createdBy: string;
    volumeDiscount: number | null;

};

export enum TransactionStatus {
    in_progress = 'in_progress',
    complete = 'complete',
}

export enum DeliveryOption {
    OPTION_A = 'OPTION_A',
    OPTION_B = 'OPTION_B',
}

export type ItemType = {
    id: number;
    productId: number;
    product?: ProductType;
    quantity: number;
    totalSavings: number | null;
    totalPoints: number | null;
    totalPrice?: number | null;
};
