import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Container, InnerContainer } from 'styles/common';

import withAuthorization from 'containers/withAuthorization';
import HomePage from 'views/HomePage';

const TransactionPage = lazy(() => import('views/TransactionPage'));
const SuppliersPage = lazy(() => import('views/SuppliersPage'));
const SupplierPage = lazy(() => import('views/SupplierPage'));
const LoginPage = lazy(() => import('views/LoginPage'));
const DashboardPage = lazy(() => import('views/Dashboard'));
const AdminPage = lazy(() => import('views/AdminPage'));
const UserDetailsPage = lazy(() => import('views/UserDetailsPage'));
const ProfilePage = lazy(() => import('views/ProfilePage'));
const NotFound = lazy(() => import('views/NotFound'));
const SupplierTransactionPage = lazy(() => import('views/SupplierTransactionPage'));

function Routes() {
    return (
        <Container>
            <Suspense
                fallback={
                    <InnerContainer>
                        <div>Loading...</div>
                    </InnerContainer>
                }
            >
                <Switch>
                    <Route path="/" component={HomePage} exact />
                    <Route path="/login" component={LoginPage} exact />
                    <Route path="/dashboard" component={withAuthorization(DashboardPage)} exact />
                    <Route path="/admin" component={withAuthorization(AdminPage)} exact />
                    <Route
                        path="/admin/users/:id"
                        component={withAuthorization(UserDetailsPage)}
                        exact
                    />
                    <Route
                        path="/admin/create-user"
                        component={withAuthorization(UserDetailsPage)}
                        exact
                    />
                    <Route path="/profile" component={withAuthorization(ProfilePage)} exact />
                    <Route
                        path="/suppliers/:id"
                        component={withAuthorization(SupplierPage)}
                        exact
                    />
                    <Route path="/suppliers" component={withAuthorization(SuppliersPage)} exact />
                    <Route
                        path="/transactions/:id"
                        component={withAuthorization(TransactionPage)}
                        exact
                    />
                    <Route
                        path="/supplier-transactions/:id"
                        component={withAuthorization(SupplierTransactionPage)}
                        exact
                    />
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </Container>
    );
}

export default Routes;
